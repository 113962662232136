
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45unbiasedGiGe9q2rznMeta } from "/app/app/pages/about-unbiased.vue?macro=true";
import { default as accountants_45for_45small_45businessZHBbFen0kQMeta } from "/app/layers/campaigns/app/pages/campaigns/(information)/accountants-for-small-business.vue?macro=true";
import { default as nhs_45pension_45adviceAgAIjKLyfDMeta } from "/app/layers/campaigns/app/pages/campaigns/(information)/nhs-pension-advice.vue?macro=true";
import { default as online_45financial_45adviserst89in30OUMeta } from "/app/layers/campaigns/app/pages/campaigns/(information)/online-financial-adviser.vue?macro=true";
import { default as wealth_45manager8SKvU69sJ9Meta } from "/app/layers/campaigns/app/pages/campaigns/(information)/wealth-manager.vue?macro=true";
import { default as _91offer_93FwLEUAEhhlMeta } from "/app/layers/campaigns/app/pages/campaigns/[offer].vue?macro=true";
import { default as free_45pension_45guideOAvUnNKFyAMeta } from "/app/layers/campaigns/app/pages/campaigns/free-pension-guide.vue?macro=true";
import { default as how_45to_45get_45a_45mortgage_45free_45mortgage_45guideYSAXCm5oZmMeta } from "/app/layers/campaigns/app/pages/campaigns/how-to-get-a-mortgage-free-mortgage-guide.vue?macro=true";
import { default as _91slug_93SvxBf3x8S0Meta } from "/app/layers/campaigns/app/pages/campaigns/p/[slug].vue?macro=true";
import { default as _91article_93QYQgGVQN4jMeta } from "/app/app/pages/discover/[category]/[subcategory]/[article].vue?macro=true";
import { default as indexCFmBM2I6NQMeta } from "/app/app/pages/discover/[category]/[subcategory]/index.vue?macro=true";
import { default as index3SVhwrpbqxMeta } from "/app/app/pages/discover/[category]/index.vue?macro=true";
import { default as glossaryMmBIrmRPmFMeta } from "/app/app/pages/glossary.vue?macro=true";
import { default as indexM6mJRhIbwTMeta } from "/app/app/pages/index.vue?macro=true";
import { default as _91page_93FvdFtPD95iMeta } from "/app/app/pages/legals/[page].vue?macro=true";
import { default as indexwd76BTlTyjMeta } from "/app/app/pages/legals/index.vue?macro=true";
import { default as _91article_93nIQjLljvCMMeta } from "/app/layers/news/app/pages/news/[subcategory]/[article].vue?macro=true";
import { default as indexiQWLozPVpiMeta } from "/app/layers/news/app/pages/news/[subcategory]/index.vue?macro=true";
import { default as indexGXPi9SfDBeMeta } from "/app/layers/news/app/pages/news/index.vue?macro=true";
import { default as _91article_93lGV1eRhWXfMeta } from "/app/layers/pro/app/pages/pro/discover/[category]/[article].vue?macro=true";
import { default as indexX7cagupqRiMeta } from "/app/layers/pro/app/pages/pro/discover/[category]/index.vue?macro=true";
import { default as indexnNrXFIkYruMeta } from "/app/layers/pro/app/pages/pro/index.vue?macro=true";
import { default as site_45mapZFJbRXPoKDMeta } from "/app/app/pages/site-map.vue?macro=true";
export default [
  {
    name: "about-unbiased",
    path: "/about-unbiased",
    component: () => import("/app/app/pages/about-unbiased.vue")
  },
  {
    name: "campaigns-accountants-for-small-business",
    path: "/campaigns/accountants-for-small-business",
    component: () => import("/app/layers/campaigns/app/pages/campaigns/(information)/accountants-for-small-business.vue")
  },
  {
    name: "campaigns-nhs-pension-advice",
    path: "/campaigns/nhs-pension-advice",
    component: () => import("/app/layers/campaigns/app/pages/campaigns/(information)/nhs-pension-advice.vue")
  },
  {
    name: "campaigns-online-financial-adviser",
    path: "/campaigns/online-financial-adviser",
    component: () => import("/app/layers/campaigns/app/pages/campaigns/(information)/online-financial-adviser.vue")
  },
  {
    name: "campaigns-wealth-manager",
    path: "/campaigns/wealth-manager",
    component: () => import("/app/layers/campaigns/app/pages/campaigns/(information)/wealth-manager.vue")
  },
  {
    name: "campaigns-offer",
    path: "/campaigns/:offer()",
    meta: _91offer_93FwLEUAEhhlMeta || {},
    component: () => import("/app/layers/campaigns/app/pages/campaigns/[offer].vue")
  },
  {
    name: "campaigns-free-pension-guide",
    path: "/campaigns/free-pension-guide",
    component: () => import("/app/layers/campaigns/app/pages/campaigns/free-pension-guide.vue")
  },
  {
    name: "campaigns-how-to-get-a-mortgage-free-mortgage-guide",
    path: "/campaigns/how-to-get-a-mortgage-free-mortgage-guide",
    component: () => import("/app/layers/campaigns/app/pages/campaigns/how-to-get-a-mortgage-free-mortgage-guide.vue")
  },
  {
    name: "campaigns-p-slug",
    path: "/campaigns/p/:slug()",
    meta: _91slug_93SvxBf3x8S0Meta || {},
    component: () => import("/app/layers/campaigns/app/pages/campaigns/p/[slug].vue")
  },
  {
    name: "discover-category-subcategory-article",
    path: "/discover/:category()/:subcategory()/:article()",
    component: () => import("/app/app/pages/discover/[category]/[subcategory]/[article].vue")
  },
  {
    name: "discover-category-subcategory",
    path: "/discover/:category()/:subcategory()",
    component: () => import("/app/app/pages/discover/[category]/[subcategory]/index.vue")
  },
  {
    name: "discover-category",
    path: "/discover/:category()",
    component: () => import("/app/app/pages/discover/[category]/index.vue")
  },
  {
    name: "glossary",
    path: "/glossary",
    component: () => import("/app/app/pages/glossary.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/app/pages/index.vue")
  },
  {
    name: "legals-page",
    path: "/legals/:page()",
    meta: _91page_93FvdFtPD95iMeta || {},
    component: () => import("/app/app/pages/legals/[page].vue")
  },
  {
    name: "legals",
    path: "/legals",
    component: () => import("/app/app/pages/legals/index.vue")
  },
  {
    name: "news-subcategory-article",
    path: "/news/:subcategory()/:article()",
    component: () => import("/app/layers/news/app/pages/news/[subcategory]/[article].vue")
  },
  {
    name: "news-subcategory",
    path: "/news/:subcategory()",
    component: () => import("/app/layers/news/app/pages/news/[subcategory]/index.vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/app/layers/news/app/pages/news/index.vue")
  },
  {
    name: "pro-discover-category-article",
    path: "/pro/discover/:category()/:article()",
    component: () => import("/app/layers/pro/app/pages/pro/discover/[category]/[article].vue")
  },
  {
    name: "pro-discover-category",
    path: "/pro/discover/:category()",
    component: () => import("/app/layers/pro/app/pages/pro/discover/[category]/index.vue")
  },
  {
    name: "pro",
    path: "/pro",
    component: () => import("/app/layers/pro/app/pages/pro/index.vue")
  },
  {
    name: "site-map",
    path: "/site-map",
    component: () => import("/app/app/pages/site-map.vue")
  }
]